.Footer-bg {
    background-color: #2894de;
    border-radius: 0px 200px 0px 0px;
}
.Footer {
    color: #ffffff;
    text-align: left;
}
.Footer-title {
    font-family: "Lato", sans-serif; 
    font-style: normal; 
    font-weight: normal; 
    font-size: 30px; 
    line-height: 100%;
    padding-left: 94px;
    padding-top: 88px;
}
.Footer-body1 {
    font-family: "Lato", sans-serif; 
    font-style: normal; 
    font-weight: normal; 
    font-size: 18px; 
    line-height: 100%;
    padding-top: 19px;
    padding-left: 132px;
}
.Footer-body2 {
    font-family: "Lato", sans-serif; 
    font-style: normal; 
    font-weight: normal; 
    font-size: 18px; 
    /* line-height: 100%; */
    padding-top: 19px;
    padding-bottom: 88px;
    padding-left: 94px;
}
.Footer a {
    color: #ffffff;
    font-weight: bold;
    text-decoration: underline;
}
.schedule-btn {
    font-family: "Lato", sans-serif !important; 
    font-style: normal !important; 
    font-weight: normal !important;
    font-size: 18px !important; 
    background-color: #b8003f;
    text-decoration: none !important;
    padding: 8px 12px;
    border: 2px solid #b8003f;
}
.schedule-btn:hover {
    color: #fff;
    background-color: #7b0027;
    border-color: #7b0027;
}
.email-input {
    width: 319px !important;
    height: 41px !important;
}
@media screen and (max-width: 767px) {
    .Footer-title {
        padding-top: 40px !important;
        padding-left: 44px !important;
    }
    .Footer-body1 {
        padding-left: 44px !important;
    }
    .Footer-body2 {
        padding-left: 44px !important;
    }
}
@media screen and (max-width: 521px) {
    /* .Footer-title {
        font-size: 26px !important;
    } */
    .Footer {
        border-radius: 0px 110px 0px 0px;
    }
}
@media screen and (max-width: 380px) {
    .email-input {
        width: 250px !important;
    }
}
@media screen and (max-width: 375px) {
    /* .Footer-title {
        font-size: 24px !important;
    } */
    .Footer-title {
        padding-left: 25px !important;
    }
    .Footer-body1 {
        padding-left: 25px !important;
    }
    .Footer-body2 {
        padding-left: 25px !important;
    }
}
@media screen and (min-width: 1200px) {
    .Footer-title {
        padding-left: 0px !important;
    }
    .Footer-body1 {
        padding-left: 0px !important;
    }
    .Footer-body2 {
        padding-left: 0px !important;
    }
}