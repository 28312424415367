.modal-header-style {
    background: #B8003F;
    border-radius: 4px;
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 100%;
    color: #FFFFFF;
}
.modal-body-style {
    font-family: "Lato", sans-serif !important;
}
.close {
    color: #ffffff !important;
    opacity: 1 !important;
    text-shadow: none !important;
}
.modal-dialog {
    width: 558px;
    max-width: 558px !important;
}
.modal-footer-btn1 {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: #2C2C2C !important;

    width: 70px;
    height: 36px;
    background-color: #FFFFFF !important;
    border: 1px solid #E0E0E0 !important;
    box-sizing: border-box;
    border-radius: 5px !important;
}
.modal-footer-btn2 {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: #FFFFFF !important;

    background-color: #B8003F !important;
    padding: 10px 24px;
    width: 92px;
    height: 36px;
}
@media screen and (max-width: 576px) {
    .modal-dialog {
        width: 100%;
        max-width: none !important;
    }
}