.calc-button {
    text-align: center;
}
.calc-button-title {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 23px;
    line-height: 100%;
    color: #333333;
}
.calc-button-body {
    display: flex;
    padding-top: 19px;
}
.calc-button-body .form-control {
    padding: 45px 5px !important;
}
.calc-input {
    height: 62px !important;

    font-family: "Lato", sans-serif;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 70px !important;
    line-height: 100% !important;
    /* or 70px */
    align-items: center !important;
    text-align: center !important;
    /* Blue */
    color: #4BA1D9 !important;
    border: 3px solid #333333 !important;
    box-shadow: inset 0 0 3px 0.2rem rgb(116 118 119 / 24%) !important;
}
.calc-input:focus {
    /* border-color: #333333 !important; */
    box-shadow: inset 0 0 3px 0.2rem rgb(116 118 119 / 24%) !important;
}
.font-btn-style {
    margin: auto 0px !important;
    color: #4BA1D9;
    font-size: 40px !important;
    cursor: pointer;
}
.font-question-style {
    margin: auto 0px !important;
    color: #333333;
    font-size: 21px !important;
    cursor: pointer;
}
@media screen and (max-width: 767px) {
    .calc-button {
        padding-top: 15px;
        padding-left: 100px;
        padding-right: 100px;
    }
}
@media screen and (max-width: 440px) {
    .calc-button {
        padding-left: 30px;
        padding-right: 30px;
    }
}
@media screen and (max-width: 697px) {
    .calc-button-title {
        font-size: 23px !important;
    }
    .calc-input {
        font-size: 35px !important;
    }
    .calc-button-body .form-control {
        padding: 25px 5px !important;
    }
    .font-btn-style {
        font-size: 30px !important;
    }
    .font-question-style {
        font-size: 25px !important;
    }
}