.navbar-brand img {
    max-width: 165px;
}
.img-responsive {
    height: auto;
}
.navbar {
    padding: 0px 7px !important;
}
.navbar-brand {
    padding: 0px 15px 0 15px !important;
    margin: 0px 0px 0px -15px !important;
}
.navbar-nav {
    font-family: "Lato", sans-serif;
    font-size: 18px;
}
.dropdown-menu>a {
    font-family: "Lato", sans-serif !important;
    font-size: 18px !important;
}
.nav-item>a {
    color: #414042 !important;
}
.navbar-nav>li>a:hover, .navbar-nav>li>a.active {
    color: #b8003f !important;
}
.navbar-menu {
    padding: 33.5px 15px !important;
}
.contact-us:hover {
    text-decoration: underline !important;
}
@media screen and (max-width: 768px) {
	.navbar {min-height: 74px !important;}
	.navbar-brand {padding: 20px 15px 0 15px !important; height: 74px !important;}
}
.demo-btn {
    padding: 6px 12px;
    margin-top: 26px;
    color: #fff !important;
}
.navbar-nav li a.demo-btn {
    font-family: "Lato", sans-serif;
    font-size: 18px;
    padding: 6px 12px !important; 
    /* margin-top: 26px !important;  */
    color: #fff !important;
    background-color: #2894de !important; 
}
.navbar-nav li a.demo-btn:hover {
    /* background-color: #2477b0 !important; 
    color: #fff !important;  */
    color: #b8003f !important;
    background-color: transparent !important;
    text-decoration: none!important;
}
.customer-login-link:hover {
    color: #b8003f !important;
    background-color: transparent !important;
    text-decoration: underline !important;
}
@media screen and (max-width: 995px) {
	.right-bar {display: none !important;}
}
@media screen and (max-width: 1145px) {
	.contact-hidden {display: none !important;}
}