.Header {
    background-color: #2894de;
    color: #ffffff;
    border-radius: 0px 0px 0px 167px;
    text-align: left;
}
.Header-title {
    font-family: "Lato", sans-serif; 
    font-style: normal; 
    font-weight: normal; 
    font-size: 36px; 
    line-height: 100%;
    padding-left: 89px;
    padding-top: 163px;
}
.Header-body {
    font-family: "Lato", sans-serif; 
    font-style: normal; 
    font-weight: normal; 
    font-size: 18px; 
    /* line-height: 100%; */
    padding-top: 11px;
    padding-left: 89px;
}
@media screen and (max-width: 767px) {
    .Header-title {
        /* font-size: 40px !important; */
        padding-top: 123px !important;
        padding-left: 44px !important;
    }
    .Header-body {
        /* font-size: 22px !important; */
        padding-top: 5px !important;
        padding-left: 44px !important;
    }
}
@media screen and (min-width: 1200px) {
    .Header-title {
        padding-left: 0px !important;
    }
    .Header-body {
        padding-left: 0px !important;
    }
}