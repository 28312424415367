.replacementcost {
    padding-top: 49px;
    text-align: left;
}
.replacementcost-header {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 100%;
    color: #F4AD27;
    padding-left: 94px;
    padding-bottom: 58px;
}
@media screen and (max-width: 697px) {
    .replacementcost-header {
        padding-left: 44px;
    }
}
@media screen and (max-width: 375px) {
    .replacementcost-header {
        padding-left: 25px;
    }
    /* .replacementcost-header {
        font-size: 30px;
    } */
}
@media screen and (min-width: 1200px) {
    .replacementcost-header {
        padding-left: 0px !important;
    }
}