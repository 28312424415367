.employee {
    padding-top: 80px;
    text-align: left;
}
.employee-header {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 100%;
    color: #4BA1D9;
    padding-left: 94px;
    padding-bottom: 37px;
}
.employee-description {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #333333;
    padding-left: 94px;
    padding-bottom: 37px;
}
@media screen and (max-width: 697px) {
    .employee-header {
        padding-left: 44px;
    }
    .employee-description {
        padding-left: 44px;
    }
}
@media screen and (max-width: 375px) {
    .employee-header {
        padding-left: 25px;
        padding-bottom: 15px;
        /* font-size: 30px; */
    }
    .employee-description {
        padding-left: 25px;
        padding-bottom: 0px;
        /* font-size: 20px; */
    }
}
@media screen and (min-width: 1200px) {
    .employee-header {
        padding-left: 0px !important;
    }
    .employee-description {
        padding-left: 0px !important;
    }
}