.roiberke-bg {
    background: #E0E0E0;
    border-radius: 0px 0px 0px 142px;
}
.roiberke {
    padding: 56px 75px 106px 94px;
    text-align: left;
}
.roiberke-header {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 100%;
    color: #4BA1D9;
    padding-bottom: 37px;
}
.roiberke-description {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #333333;
    padding-bottom: 37px;
}
@media screen and (max-width: 697px) {
    .roiberke {
        padding-left: 0px;
        padding-right: 0px;
    }
    .roiberke-header {
        padding-left: 44px;
    }
    .roiberke-description {
        padding-left: 44px;
    }
}
@media screen and (max-width: 375px) {
    .roiberke-header {
        padding-left: 25px;
        /* font-size: 30px; */
        padding-bottom: 35px;
    }
    .roiberke-description {
        padding-left: 25px;
        padding-bottom: 0px;
        /* font-size: 20px; */
    }
}
@media screen and (min-width: 1200px) {
    .roiberke {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .roiberke-header {
        padding-left: 0px !important;
    }
    .roiberke-description {
        padding-left: 0px !important;
    }
}