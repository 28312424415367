.form-control[readonly] {
    background-color: #fff !important;
    opacity: 1;
}
.modal-body .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #ced4da !important;
    outline: 0;
    box-shadow: unset !important;
}
.input-element {
    padding-top: 10px;
    padding-left: 79px;
    padding-right: 99px;
    margin: auto 0px !important;
}
.input-left {
    font-family: "Lato", sans-serif !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 23px !important;
    line-height: 100% !important;
    color: #333333 !important;
}
.input-left-wrap {
    margin: auto 0px !important;
}
.input-right {
    font-family: "Lato", sans-serif !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 31px !important;
    line-height: 100% !important;
    /* or 31px */

    text-align: right !important;

    /* Font Color Berke */

    color: #333333 !important;
    border: 0px !important;
}
.input-right-border {
    font-family: "Lato", sans-serif !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 31px !important;
    line-height: 100% !important;
    /* or 31px */

    text-align: right !important;

    /* Font Color Berke */

    color: #333333 !important;
}
.input-append-symbol {
    font-family: "Lato", sans-serif !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 31px !important;
    line-height: 100% !important;
    /* or 31px */

    text-align: right !important;

    /* Font Color Berke */

    color: #333333 !important;
    background-color: #fff !important;
    border-left: 0px solid !important;
}
@media screen and (max-width: 697px) {
    /* .input-left {
        font-size: 23px !important;
    } */
    .input-right {
        font-size: 23px !important;
    }
    .input-right-border {
        font-size: 23px !important;
    }
    .input-append-symbol {
        font-size: 23px !important;
    }
    .input-element {
        padding-left: 44px !important;
        padding-right: 44px !important;
    }
}
@media screen and (max-width: 375px) {
    .input-left {
        font-size: 19px !important;
    }
    .input-right {
        font-size: 20px !important;
    }
    .input-right-border {
        font-size: 20px !important;
    }
    .input-append-symbol {
        font-size: 20px !important;
    }
    .input-element {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
}
@media screen and (max-width: 768px) and (min-width: 768px) {
    .input-right {
        font-size: 36px !important;
    }
    .input-right-border {
        font-size: 36px !important;
    }
    .input-append-symbol {
        font-size: 36px !important;
    }
}
@media screen and (min-width: 1200px) {
    .input-element {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .container-fluid {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}