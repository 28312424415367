.display-element {
    text-align: center;
}
.display-ele-title {
    height: 35px;
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 23px;
    line-height: 100%;
    /* or 31px */
    align-items: center;
    text-align: center;
    /* Font Color Berke */
    color: #333333;
}
.display-ele-body {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 70px;
    line-height: 100%;
    /* or 70px */
    align-items: center;
    text-align: center;
    /* Pink Red */
    color: #D51B5E;
    padding-top: 35px;
}
@media screen and (max-width: 575px) {
    .display-element {
        padding-top: 0px;
    }
    .display-ele-body {
        padding-top: 15px;
    }
}
@media screen and (max-width: 767px) {
    .display-element {
        padding-top: 15px;
    }
}
@media screen and (max-width: 697px) {
    .display-ele-title {
        height: 100% !important;
        /* font-size: 23px !important; */
    }
    .display-ele-body {
        font-size: 44px !important;
    }
}
@media screen and (max-width: 1123px) and (min-width: 769px) {
    .display-ele-title {
        height: 75px !important;
        /* font-size: 27px !important; */
    }
    .display-ele-body {
        font-size: 45px !important;
        padding-top: 15px !important;
    }
}
@media screen and (max-width: 768px) and (min-width: 768px) {
    .display-ele-title {
        height: 70px !important;
        /* font-size: 27px !important; */
    }
    .display-ele-body {
        font-size: 37px !important;
    }
}
@media screen and (max-width: 375px) {
    .display-ele-title {
        height: 100% !important;
        /* font-size: 27px !important; */
    }
    .display-ele-body {
        font-size: 40px !important;
    }
    .display-ele-title {
        font-size: 20px !important;
    }
}
/* @media screen and (max-width: 1203px) and (min-width: 1103px) {
    .display-ele-title {
        font-size: 28px !important;
    }
}
@media screen and (max-width: 1102px) and (min-width: 960px) {
    .display-ele-title {
        font-size: 24px !important;
    }
} */